import React from "react";

const Basket = () => {
    return (
        <div>
            Basket
        </div>
    )
}

export default Basket;